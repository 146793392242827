import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";
export const useStore = defineStore("store", {
  state: () => ({
    searchCount: 0,
    allowedSearches: 3,
    userTier: "",
    sportOdds: {},
    selectedSport: "americanfootball_nfl",
    isCustomMatchupVisible: false,
    isLoggedIn: false,
    userEmail: "",
  }),

  getters: {
    sportDisplayName: (state) => {
      const sportMap = {
        americanfootball_nfl: "NFL",
        baseball_mlb: "MLB",
        icehockey_nhl: "NHL",
        basketball_nba: "NBA",
        soccer_uefa_european_championship: "UEFA Cup",
        soccer_uefa_nations_league: "UEFA Nations",
        // Add more mappings as needed
      };
      return sportMap[state.selectedSport] || state.selectedSport; // Fallback to the original value if no mapping found
    },
  },

  actions: {
    setSelectedSport(sportId) {
      this.selectedSport = sportId;
    },
    toggleCustomMatchupVisibility() {
      this.isCustomMatchupVisible = !this.isCustomMatchupVisible;
    },
    getAllowedSearchesByTier(tier: string): number {
      switch (tier) {
        case "rookie":
          return 100;
        case "pro":
          return 300;
        case "free":
          return 10;
        default:
          return 3;
      }
    },
    setUserTier(tier: string) {
      this.userTier = tier;
      this.allowedSearches = this.getAllowedSearchesByTier(tier);
    },

    async checkLoginStatus() {
      console.log("Fetching user tier and calculating remaining searches...");
      try {
        const response = await axios.get("https://back.betboss.ai/check-login", {
          withCredentials: true 
        });
        this.isLoggedIn = response.data.logged_in;

        if (this.isLoggedIn) {
          this.userEmail = response.data.user.email;
          const tier = response.data.user.fields.tier || "";
          this.userTier = tier;
          this.allowedSearches = this.getAllowedSearchesByTier(tier);
          return;
        }

        // Reset state when user is not logged in
        this.userTier = "";
        this.userEmail = "";
        this.allowedSearches = this.getAllowedSearchesByTier("");
      } catch (error) {
        console.error("Error fetching user tier:", error);
        this.isLoggedIn = false;
        this.userTier = "";
        this.userEmail = "";
        this.allowedSearches = this.getAllowedSearchesByTier("");
      }
    },

    updateSportOdds(newOdds) {
      console.log("Updating sport odds in the store...");
      this.sportOdds = newOdds;
    },

    incrementSearchCount() {
      this.searchCount++;
      console.log("Search count:", this.searchCount);

      // Check if we need to redirect
      if (this.searchCount > this.allowedSearches) {
        this.searchCount = this.allowedSearches; // Cap the search count

        // Return a boolean indicating that a redirect is needed
        return true;
      }
      return false;
    },
  },

  persist: true,
});
