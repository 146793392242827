<template>
  <div>
    <h1>Reset</h1>
    <p>Cookies and local storage have been cleared.</p>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  // Clear all cookies
  document.cookie.split(";").forEach((c) => {
    const cookieName = c.split("=")[0].trim();
    const expires = "=;expires=" + new Date().toUTCString() + ";path=/";
    
    // Clear cookie for current domain
    document.cookie = cookieName + expires;
    
    // Clear cookie for .betboss.ai
    document.cookie = cookieName + expires + ";domain=.betboss.ai";
    
    // Clear cookie for auth.betboss.ai
    document.cookie = cookieName + expires + ";domain=auth.betboss.ai";
  });

  // Clear local storage
  localStorage.clear();
});
</script>
