<template>
  <div>
    <h1>Odds Information</h1>
    <select v-model="selectedSport">
      <option value="basketball_nba">NBA</option>
      <option value="icehockey_nhl">NHL</option>
      <option value="baseball_mlb">MLB</option>
      <option value="soccer_uefa_european_championship">UEFA Cup</option>
      <option value="americanfootball_nfl">NFL</option>
      <option value="soccer_spain_la_liga">La Liga</option>
    </select>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else>
      <table class="odds-table">
        <thead>
          <tr>
            <th>Sport</th>
            <th>Teams</th>
            <th>Bookmaker</th>
            <th>Market</th>
            <th>Outcomes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="game in oddsData" :key="game.id" class="game-row">
            <td>{{ game.sport_title }}</td>
            <td>
              {{ game.home_team }} vs 
              {{ game.away_team }}
            </td>
            <td colspan="3">
              <table>
                <tr v-for="bookmaker in game.bookmakers" :key="bookmaker.key">
                  <td>{{ bookmaker.title }}</td>
                  <td v-for="market in bookmaker.markets" :key="market.key">
                    {{ market.key }}
                    <ul>
                      <li v-for="outcome in market.outcomes" :key="outcome.name">
                         {{ outcome.price }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

const oddsData = ref([]);
const loading = ref(true);
const selectedSport = ref('baseball_mlb'); 

const fetchOdds = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`https://back.betboss.ai/get_all_odds?sport_name=${selectedSport.value}`);
    oddsData.value = response.data;
    loading.value = false;
  } catch (error) {
    console.error('Failed to fetch odds:', error);
    loading.value = false;
  }
};

onMounted(() => {
  fetchOdds();
});

watch(selectedSport, () => {
  fetchOdds();
});
</script>
<style scoped>
h1, h2, h3, h4 {
  margin-bottom: 0.5rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
.odds-table {
  width: 100%;
  border-collapse: collapse;
}
.odds-table th, .odds-table td {
  border: 1px solid #444;  
  padding: 8px;
  text-align: left;
  color: #ddd;  
  background-color: #333;  
}
.odds-table tr:nth-child(even) {
  background-color: #2a2a2a;  
}
.odds-table tr:hover {
  background-color: #1f1f1f; 
}
</style>
