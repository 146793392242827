<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="info-modal-content" @click.stop>
      <button class="close-button" @click="closeModal">×</button>
      
      <h2>Why betBOSS.ai?</h2>
      
      <div class="content-section">
        <h3>🤖 Advanced AI Analysis</h3>
        <p>Our AI system analyzes vast amounts of sports data to provide insights that traditional methods might miss.</p>
      </div>

      <div class="content-section">
        <h3>📊 Data-Driven Decisions</h3>
        <p>We combine historical data, current team performance, player statistics, and real-time odds to give you comprehensive analysis.</p>
      </div>

      <div class="content-section">
        <h3>⚡ Real-Time Updates</h3>
        <p>Get instant access to the latest odds and analysis, helping you make informed decisions quickly.</p>
      </div>

      <div class="content-section">
        <h3>💡 Smart Insights</h3>
        <p>Our AI doesn't just crunch numbers - it provides contextual insights and explains its reasoning.</p>
      </div>

      <div class="content-section">
        <h3>🎯 Focused Analysis</h3>
        <p>We focus on finding value bets and identifying opportunities that others might overlook.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';

const props = defineProps<{
  isOpen: boolean
}>();

const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-modal-content {
  background-color: #333;
  padding: 2rem;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid #4e9f3d;
  color: #fff;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4e9f3d;
  padding: 5px 10px;
}

.close-button:hover {
  color: #fff;
}

h2 {
  color: #4e9f3d;
  margin-bottom: 1.5rem;
  text-align: center;
}

.content-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #242424;
  border-radius: 8px;
  border: 1px solid #4e9f3d;
}

h3 {
  color: #4e9f3d;
  margin-bottom: 0.5rem;
}

p {
  color: #d0e2cd;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .info-modal-content {
    width: 95%;
    padding: 1rem;
  }
}
</style> 