<template>
  <div>
    <TopBar />
    <div v-if="loading">Loading...</div>
    <div v-else-if="hasSubscription" class="stripe-buttons-grid">
      <h2>Account already created for {{ userEmail }}. Please <a href="https://back.betboss.ai/protected">login</a></h2>
    </div>
    <div v-else class="stripe-buttons-grid">
      <!-- <h1 style="margin:0">Win or it's free!</h1> -->
      <!-- <p style="margin:0">If our picks don't win, email us your betslip and we will refund your membership fee.</p> -->
      <div v-for="button in stripeButtons" :key="button.id">
        <p>{{ button.name }}: {{ button.picks }} picks/week</p>
        <stripe-buy-button 
          :buy-button-id="button.id" 
          :publishable-key="publishableKey" 
          :client-reference-id="clientReferenceId"
          @click="handleSubscriptionClick(button.name)"
        >
        </stripe-buy-button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import TopBar from "./TopBar.vue";
import Footer from "./Footer.vue";
import { storeToRefs } from "pinia";
import { useStore } from "../stores/store";
import { useGtm } from "@gtm-support/vue-gtm";
import axios from 'axios';

const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);

const store = useStore();
const { userEmail } = storeToRefs(store);

const clientReferenceId = ref('');
const publishableKey = "pk_live_51OlbxxCwPAlJePWckrBDCzu7rNypjkdmITTtwNz7z2Y2fTav6i8TXAYOtzECVMPaODKjbRNDU00O6VvtOlzwfPQN00KVmzRTmO";
const stripeButtons = [
  { id: "buy_btn_1PYtvvCwPAlJePWclTe1MR2E", name: "Rookie", picks: 100 },
  { id: "buy_btn_1PYtyfCwPAlJePWc9csHOkOw", name: "Pro", picks: 300 },
  // { id: "buy_btn_1PYtOLCwPAlJePWc6CmOGdtY", name: "Elite", picks: 500 },
];

const loading = ref(true);
const hasSubscription = ref(false);

const handleSubscriptionClick = (planName: string) => {
  trackEvent({
    event: "subscription_click",
    event_category: "ecommerce",
    event_action: "select_plan",
    event_label: planName.toLowerCase(),
    noninteraction: false
  });
};

onMounted(async () => {
  // Load Stripe script
  const stripeScript = document.createElement('script');
  stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
  stripeScript.async = true;
  document.head.appendChild(stripeScript);

  // Check for existing STRIPE subscriptions
  try {
    if (userEmail.value) {
      const response = await axios.get(`https://back.betboss.ai/subscriptions/${userEmail.value}`);
      hasSubscription.value = response.data.length > 0;
      
      // If user has a subscription, set up their access
      if (hasSubscription.value && response.data[0]) {
        const subscription = response.data[0];
        const tier = subscription.product_name.toLowerCase();
        
        // Update store
        store.setUserTier(tier);
        store.searchCount = 0; // Reset search count
        
        // Get customer ID and update store
        try {
          const customerResponse = await axios.get(
            `https://back.betboss.ai/get-customer-id-by-email/${encodeURIComponent(userEmail.value?.trim().replace(/['"]/g, ''))}`
          );
          if (customerResponse.data && customerResponse.data.tier) {
            store.setUserTier(customerResponse.data.tier);
          }
        } catch (error) {
          console.error('Error fetching customer details:', error);
        }
      }
    }
  } catch (error) {
    console.error('Error checking subscriptions:', error);
  } finally {
    loading.value = false;
  }

  // Watch for Stripe iframe creation and message events
  window.addEventListener('message', function(event) {
    // Only handle messages from Stripe domains
    if (event.origin.includes('stripe.com')) {
      try {
        const data = event.data;
        // Check if this is a successful purchase event
        if (data.type === 'buy-button:success') {
          // Handle successful purchase
          console.log('Purchase successful!', data);
          // Fire your tracking events here
        }
      } catch (e) {
        console.error('Error handling Stripe message:', e);
      }
    }
  });
});
</script>

<style scoped>
.stripe-buttons-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

:deep(.stripe-buy-button) {
  flex: 1;
  min-width: 0;
}

:deep(.stripe-buy-button iframe) {
  width: 100% !important;
  min-width: 0 !important;
}

@media (max-width: 768px) {
  .stripe-buttons-grid {
    padding: 1rem;
  }
}
</style>
