<template>
THIS Is DEV PAge
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, computed, watch, onUnmounted } from "vue";
import axios from "axios";
import { useStore } from "../stores/store";
const store = useStore();

</script>

<style scoped>

</style>
