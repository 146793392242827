<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Games from "./Games.vue";
import TopBar from "./TopBar.vue";
import Footer from "./Footer.vue";
const router = useRouter();

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const affid = urlParams.get('aff');
  const affS1 = urlParams.get('affS1');
  
  const redirectAffids = ['712816', '686209', '600593', '628183'];
  
  if ((affid && redirectAffids.includes(affid)) || (affS1 && redirectAffids.includes(affS1))) {
    window.location.href = 'https://www.google.com';
  }
});

</script>

<template>
  <div>
    <TopBar />
  </div>
  <div class="container">
    <div class="table-ui">
      <Games />
    </div>
  </div>

  <div>
    <Footer />
  </div>
</template>

<style scoped>
.container {
  display: flex;
}

.chat-ui {
  width: 50%;
  /* Add any additional styling for chat UI here */
}

.table-ui {
  width: 100%;
  /* Add any additional styling for table UI here */
}
</style>
